<template>
  <div v-loading="specLoading">
    <sui-form
      ref="specForm"
      :list="formList"
      :defaultdata="formData"
      @submit="submit"
      submitTitle="common.submitAndNext"
    />
  </div>
</template>

<script>
import { EditSpec, GetSpecList } from "@/utils/api";
import SuiForm from "@/components/s-ui/form";
export default {
  components: {
    SuiForm,
  },
  props: {
    spuId: {
      type: String,
    },
  },
  data() {
    return {
      specLoading: false,
      formList: [
        {
          type: "colorgroup",
          name: "color",
          label: "product.color",
          rules: [
            {
              required: true,
            },
          ],
        },
        {
          type: "sizegroup",
          name: "size",
          label: "product.size",
          rules: [
            {
              required: true,
            },
          ],
        },
        // {
        //   type: "ladderprice",
        //   name: "ladderprice",
        //   label: "product.ladderprice",
        //   rules: [
        //     {
        //       required: true,
        //     },
        //   ],
        // },
      ],
      formData: {
        color: [],
        size: [],
      },
    };
  },
  created() {
    this.spuId && this.getList();
  },
  methods: {
    getList(next = false) {
      this.specLoading = true;
      GetSpecList({ spuId: this.spuId })
        .then((res) => {
          console.log("GetSpec-res:", res);
          this.$nextTick(() => {
            const outPutData = {
              ...res.data,
            };
            // this.$refs["specForm"].setForm(outPutData);
            this.formData = outPutData;
            this.$emit("success", outPutData, next);
          });
          this.error = null;
          this.specLoading = false;
        })
        .catch((err) => {
          console.log("GetSpec-err:", err);
          this.error = err;
          this.specLoading = false;
        });
    },
    submit(data) {
      console.log("submit:", data);
      this.specLoading = true;
      EditSpec({ ...data, spuId: this.spuId })
        .then((res) => {
          console.log("EditSpec-res:", res);
          this.$message({
            type: "success",
            message: this.$t("common.optSuccess"),
          });
          this.specLoading = false;
          this.getList(true);
        })
        .catch((err) => {
          console.log("EditSpec-err:", err);
          this.specLoading = false;
        });
    },
  },
};
</script>

<style>
.drawer-form {
  padding: 0 30px;
}
</style>