<template>
  <div v-loading="spuLoading">
    <sui-error v-if="error" :data="error"></sui-error>
    <sui-form
      v-else
      ref="spuForm"
      :list="formList"
      :defaultdata="formData"
      @submit="submit"
      submitTitle="common.submitAndNext"
    />
  </div>
</template>

<script>
import { AddSpu, GetSpu, EditSpu } from "@/utils/api";
import SuiForm from "@/components/s-ui/form";
import SuiError from "@/components/s-ui/error";
export default {
  props: {
    spuId: {
      type: String,
    },
  },
  components: {
    SuiForm,
    SuiError,
  },
  data() {
    return {
      spuLoading: false,
      error: null,
      formList: [
        {
          type: "input",
          name: "id",
          hidden: true,
        },
        {
          type: "input",
          name: "spuName",
          label: "product.spuName",
          rules: [
            {
              required: true,
            },
          ],
        },
        {
          type: "category",
          name: "categoryIdList",
          label: "product.categoryIdList",
          rules: [
            {
              required: true,
            },
          ],
        },
        {
          //   type: "specialitems",
          type: "goodsTypeList",
          name:
            this.$route.path == "/product/edit"
              ? "specialitems"
              : "goodsTypeList",
          label: "pricing.goodsTypeList",
          rules: [{ required: true }],
        },
        {
          type: "dictionary",
          name: "attributesList",
          label: "product.attributes",
        },
        {
          type: "brand",
          name: "brandId",
          label: "product.brandId",
        },
        {
          type: "country",
          name: "oringin",
          label: "product.oringin",
          rules: [
            {
              required: true,
            },
          ],
        },
        // {
        //   type: "number",
        //   name: "minimun",
        //   label: "product.minimun",
        //   rules: [
        //     {
        //       required: true,
        //     },
        //   ],
        // },
        // {
        //   type: "number",
        //   name: "incMinimun",
        //   label: "product.incMinimun",
        //   rules: [
        //     {
        //       required: true,
        //     },
        //   ],
        // },
        {
          type: "quantityofabox",
          name: "incMinimun",
          label: "product.incMinimun",
          rules: [
            {
              required: true,
            },
          ],
        },
        {
          type: "volume",
          name: "volume",
          label: "product.volume",
          rules: [
            {
              required: true,
            },
          ],
        },
        // {
        //   type: "boxcbm",
        //   name: "boxCbm",
        //   label: "product.boxCbm",
        //   rules: [
        //     {
        //       required: true,
        //     },
        //   ],
        // },
        {
          type: "weight",
          name: "weight",
          label: "product.weight",
          rules: [
            {
              required: true,
            },
          ],
        },
        // {
        //   type: "currency",
        //   name: "currency",
        //   label: "product.currency",
        //   rules: [
        //     {
        //       required: true,
        //     },
        //   ],
        // },
        {
          type: "number",
          name: "packaging",
          label: "product.packaging",
        },
        {
          type: "number",
          name: "graphic",
          label: "product.graphic",
        },
        {
          type: "number",
          name: "logo",
          label: "product.logo",
        },
        {
          type: "textarea",
          name: "description",
          label: "product.description",
          tips: "product.descriptionTips",
          rules: [
            {
              required: true,
            },
          ],
        },
      ],
      formData: {
        categoryIdList: [],
      },
    };
  },
  created() {
    this.spuId && this.getDetail();
  },
  methods: {
    getDetail() {
      this.spuLoading = true;
      GetSpu({ id: this.spuId })
        .then((res) => {
          console.log("GetSpu-res:", res);
          this.$store.commit("setincMinimun", res.data.incMinimun);
          localStorage.setItem("accessory", JSON.stringify(res.data.accessory));
          localStorage.setItem("spuID", res.data.id);
          this.$emit("success", res.data, false);
          this.$nextTick(() => {
            // this.$refs["spuForm"].setForm(res.data);
            this.formData = res.data;
          });
          this.error = null;
          this.spuLoading = false;
        })
        .catch((err) => {
          console.log("GetSpu-err:", err);
          this.error = err;
          this.spuLoading = false;
        });
    },
    submit(data) {
      this.spuLoading = true;
      console.log("submit:", data);
      (this.spuId ? EditSpu : AddSpu)({ ...data, id: this.spuId })
        .then((res) => {
          console.log("fun-res:", res);
          this.$message({
            type: "success",
            message: this.$t("common.optSuccess"),
          });
          this.spuLoading = false;
          this.$emit("success", res.data);
        })
        .catch((err) => {
          console.log("fun-err:", err);
          this.spuLoading = false;
        });
    },
  },
};
</script>

<style>
.drawer-form {
  padding: 0 30px;
}
</style>