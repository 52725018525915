<template>
  <div class="bulk-fill">
    <!-- {{ formList }} -->
    <sui-form
      ref="bulkFillForm"
      :inline="true"
      :list="formList"
      :defaultdata="formData"
      @submit="submit"
      submitTitle="product.bulkFill"
    />
    <!-- {{ ladderData }} -->
    <!-- <p>{{ specData }}</p> -->
  </div>
</template>

<script>
const SuiForm = () => import("@/components/s-ui/form");

export default {
  components: {
    SuiForm,
  },
  props: {
    specData: {
      type: Object,
    },
    ladderData: {
      type: Array,
    },
  },
  data() {
    return {
      formData: {},
    };
  },
  computed: {
    formList() {
      return [
        {
          type: "select",
          name: "colorId",
          label: "product.colorText",
          range: [
            {
              t: true,
              label: "product.all",
              id: 0,
            },
            ...this.specData.color
              .filter((v) => v.avaliable === 1)
              .map((color) => {
                return {
                  label: color.specName,
                  value: color.id,
                };
              }),
          ],
        },
        {
          type: "select",
          name: "sizeId",
          label: "product.sizeText",
          range: [
            {
              t: true,
              label: "product.all",
              id: 0,
            },
            ...this.specData.size
              .filter((v) => v.avaliable === 1)
              .map((size) => {
                return {
                  label: size.specName,
                  value: size.id,
                };
              }),
          ],
        },
        {
          type: "select",
          name: "ladderId",
          label: "product.ladderprice",
          range: [
            {
              t: true,
              label: "product.all",
              id: 0,
            },
            ...this.ladderData.map((ladder, index) => {
              const maxText =
                index === this.ladderData.length - 1 ? "Unlimited" : ladder.max;
              return {
                label: `${ladder.min}-${maxText}`,
                value: ladder.id,
              };
            }),
          ],
        },
        {
          type: "switch",
          name: "infStock",
          label: "product.infinity",
          setHidden: ["stock", 0, 0],
        },
        {
          type: "number",
          name: "stock",
          label: "product.stock",
          rules: [
            {
              required: true,
            },
          ],
        },
        // 两位数
        {
          type: "price",
          name: "price",
          label: "product.price",
          rules: [
            {
              required: true,
            },
          ],
        },
        //四位数
        // {
        //   type: "priceByfour",
        //   name: "price",
        //   label: "product.price",
        //   rules: [
        //     {
        //       required: true,
        //     },
        //   ],
        // },
      ];
    },
  },
  methods: {
    submit(e) {
        console.log('sku000000',e)
      this.$emit("submit", e);
    },
  },
};
</script>

<style lang="scss">
.bulk-fill {
  background-color: #f8f8f8;
  padding: 0 10px;
  border-radius: 5px;

  .el-form--inline {
    display: flex;
    justify-content: space-between;
  }
}
</style>