<template>
  <div v-loading="mediaLoading">
    <sui-error v-if="error" :data="error"></sui-error>
    <sui-form
      v-else
      ref="mediaForm"
      :list="formList"
      :defaultdata="formData"
      @submit="submit"
      submitTitle="common.submitAndNext"
    />
  </div>
</template>

<script>
import { EditSpu } from "@/utils/api";
import SuiForm from "@/components/s-ui/form";
import SuiError from "@/components/s-ui/error";
export default {
  props: {
    spuData: {
      type: Object,
    },
  },
  components: {
    SuiForm,
    SuiError,
  },
  data() {
    return {
      mediaLoading: false,
      error: null,
      formList: [
        {
          type: "input",
          name: "id",
          hidden: true,
        },
        {
          type: "uploadimage",
          name: "galleryList",
          mode: "material",
          // cropper: true,
          // cropperWitdh: 405,
          // cropperHeight: 539,
          max: 100,
          label: "product.galleryList",
          tips: "product.galleryListTips",
          rules: [
            {
              required: true,
            },
          ],
        },
        {
          type: "uploadimage",
          mode: "material",
          mediaType: "video",
          max: 1,
          name: "videoIdList",
          label: "product.video",
          tips: "product.video",
        },
        {
          type: "uploadimage",
          mode: "material",
          max: 100,
          name: "detailDawingList",
          label: "product.detailDawingList",
          tips: "product.detailDawingListTips",
          rules: [
            {
              required: true,
            },
          ],
        },
      ],
      formData: {
        galleryList: [],
        video: "",
        detailDawingList: [],
      },
    };
  },
  created() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      console.log("this.spuData:", this.spuData);
      this.spuData.id &&
        this.$nextTick(() => {
          const { id, galleryList, videoIdList, detailDawingList } =
            this.spuData;
          this.formData = {
            id,
            galleryList,
            videoIdList,
            detailDawingList,
          };
          // this.$refs["mediaForm"].setForm({
          //   id,
          //   galleryList,
          //   videoIdList,
          //   detailDawingList,
          // });
        });
    },
    submit(data) {
      console.log("submit:", data);
      this.mediaLoading = true;
      EditSpu({ ...data, id: this.spuData.id })
        .then((res) => {
          console.log("fun-res:", res);
          this.$message({
            type: "success",
            message: this.$t("common.optSuccess"),
          });
          this.$emit("success", res.data);
          this.mediaLoading = false;
        })
        .catch((err) => {
          console.log("fun-err:", err);
          this.mediaLoading = false;
        });
    },
  },
};
</script>

<style>
.drawer-form {
  padding: 0 30px;
}
</style>