<template>
  <div>
    <!-- <p>{{ value }}</p> -->
    <!-- <p>{{ discardList }}</p> -->
    <el-row :gutter="20">
      <el-col :span="11" :md="4">
        {{ $t("product.lowerprice") }}
      </el-col>
      <el-col :span="2" :md="1"> <i class="el-icon-minus" /></el-col>
      <el-col :span="11" :md="4">
        {{ $t("product.topprice") }}
      </el-col>

      <el-col :span="11" :md="4">
        {{ $t("product.leap") }}
      </el-col>
      <el-col :span="8" :md="4"> </el-col>
      <el-col :span="5" :md="2"> </el-col>
    </el-row>

    <template v-for="(item, index) in avaliableList">
      <!-- v-if="item.avaliable" -->
      <el-row
        v-if="item.avaliable"
        :key="`ladder-input-${index}`"
        style="margin-bottom: 10px"
        type="flex"
        justify="start"
        align="middle"
        :gutter="20"
      >
        <!-- min input -->
        <el-col :span="11" :md="4">
          <el-input
            v-model.number="item.min"
            readonly
            :ref="'ladder-input-min-' + index"
            class="min-input number-input"
          >
            <!-- <div slot="prepend">
            {{ $t("product.ladder") }}
          </div> -->
          </el-input>
        </el-col>
        <el-col :span="2" :md="1" class="from-to">
          <i class="el-icon-minus" />
        </el-col>
        <!-- max input -->
        <el-col :span="11" :md="4">
          <span v-if="index > 0 && index === avaliableList.length - 1"
            >Unlimited</span
          >
          <el-input-number
            v-else
            v-model.number="item.max"
            :min="item.min + 1"
            :step="1"
            step-strictly
            controls-position="right"
            :ref="'ladder-input-max-' + index"
            :placeholder="$t('product.limit')"
            class="number-input"
            @change="checkMinMaxChange()"
          >
          </el-input-number>
          <!-- @change="onMaxChange($event, index)" -->
        </el-col>
        <!-- delivery time input -->
        <el-col :span="11" :md="4">
          <el-input-number
            v-model.number="item.leap"
            :min="1"
            :step="1"
            step-strictly
            controls-position="right"
            :ref="'ladder-input-leap-' + index"
            :placeholder="$t('product.deliveryTime')"
            class="number-input"
          >
          </el-input-number>
        </el-col>
        <!-- free shipping switch -->
        <el-col :span="8" :md="4">
          <el-switch
            v-model="item.freeShipping"
            :active-text="$t('product.freeShipping')"
            :active-value="1"
            :inactive-value="0"
          >
          </el-switch>
        </el-col>
        <el-col :span="5" :md="2">
          <el-popconfirm
            v-if="item.max > 0 && item.leap > 0 && index > 1"
            :title="$t('common.confirmDelete')"
            @confirm="del(item)"
          >
            <el-button
              slot="reference"
              icon="el-icon-delete"
              type="danger"
              plain
            ></el-button>
          </el-popconfirm>
        </el-col>
      </el-row>
    </template>
    <!-- <el-row> last row is not max 0 {{ hasUnlimited }}</el-row> -->

    <el-button
      v-if="!hasEmptyNum"
      icon="el-icon-plus"
      type="primary"
      plain
      @click="add"
    ></el-button>

    <el-button
      type="primary"
      class="ladder-submit"
      @click="$emit('submit', [...avaliableList, ...discardList])"
    >
      {{ $t("product.generateSkuList") }}
    </el-button>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default() {
        return [];
      },
    },
    minimun: {
      type: Number,
    },
  },
  data() {
    return {
      defaultLadder: [
        {
          avaliable: 1,
          min: 1,
          max: 2,
          freeShipping: 0,
          leap: 1,
        },
        {
          avaliable: 1,
          min: 3,
          max: 4,
          freeShipping: 0,
          leap: 1,
        },
      ],
    };
  },
  watch: {
    hasminNum: function (newVal, oldVal) {
      console.log(newVal, oldVal, "hasminNum");
      if (newVal !== oldVal) {
        this.avaliableList[0].min = newVal;
        if ((this.avaliableList[0].min = newVal)) {
          console.log("haha");
          const skMin = newVal;
          this.avaliableList[0].max = skMin + 1;
          this.$nextTick(() => {
            this.checkMinMaxChange();
          });
        } else {
          return this.avaliableList;
        }
      }
    },
    deep: true,
  },
  computed: {
    hasminNum() {
      return this.minimun;
    },
    hasEmptyNum: function () {
      return this.avaliableList.some((v) => !v.max || !v.leap);
    },
    avaliableList: function () {
      const avaliableList = this.value.filter((v) => v.avaliable);
      if (avaliableList.length === 0) {
        // 如果列表为空，则，初始化列表数据
        return this.defaultLadder;
      } else if (avaliableList.length === 1) {
        // 某些旧数据，可能只设置了一行阶梯，默认加一行结尾
        const firstCel = avaliableList[0];
        console.log(firstCel, "firstCel");
        return [
          firstCel,
          {
            ...firstCel,
            min: firstCel.max + 1,
            max: firstCel.max + 2,
          },
        ];
      } else {
        return avaliableList;
      }
    },
    discardList() {
      return this.value.filter((v) => !v.avaliable);
    },
    // avaliableCount: function () {
    //   return this.value.filter((v) => !v.avaliable).length;
    // },
    // hasUnlimited() {
    //   if (this.value.length > 0) {
    //     const lastItem = this.value[this.value.length - 1];
    //     return lastItem.max === 0;
    //   } else {
    //     return false;
    //   }
    // },
  },
  mounted() {
    if (this.value.length > 1) {
      this.checkMinMaxChange();
    }
  },
  methods: {
    // onMaxChange(e, index) {
    //   // const nextMinRefName = "ladder-input-min-" + (index + 1);
    //   // const nextMinRefCom = this.$refs[nextMinRefName];
    //   console.log("onMaxChange:", e, index);
    //   this.checkMinMaxChange();
    // },
    // findNext(nextIndex) {
    //   const nextCelData = this.avaliableList[nextIndex];
    //   if (!nextCelData) {
    //     return null;
    //   } else if (nextCelData.avaliable) {
    //     return { nextCelData, nextIndex };
    //   } else {
    //     return this.findNext(nextIndex + 1);
    //   }
    // },
    // findUnAvaliable(item) {
    //   // const item = this.value[index]
    //   const unAvaliable = this.value
    //     .filter((v) => !v.avaliable)
    //     .find((v) => v.min === item.min && v.max === item.max);
    //   console.log("unAvaliable:", unAvaliable);
    //   return unAvaliable;
    // },
    /*checkMinMaxChange(index, value) {
      const nextIndex = index + 1;
      const nextCelDataObj = this.findNext(nextIndex);
      console.log("nextCelDataObj:", nextCelDataObj);
      if (nextCelDataObj && nextCelDataObj.nextCelData) {
        const { nextCelData, nextIndex } = nextCelDataObj;
        if (isNaN(value)) {
          this.del(nextIndex, this.avaliableList[nextIndex]);
          return false;
        }
        nextCelData.min = value;
        if (nextCelData.min < nextCelData.max) {
          // console.log(index, "nextCelData:", nextCelData);
          return false;
        } else {
          nextCelData.max++;
          this.$nextTick(() => {
            this.checkMinMaxChange(nextIndex, Number(nextCelData.max) + 1);
          });
        }
      } else {
        // console.log("no next:", index);
      }
    },*/
    checkMinMaxChange(index = 0) {
      const curItem = this.avaliableList[index];
      const curMax = curItem.max; // 本行的max
      const nextIndex = index + 1;
      const nextCel = this.avaliableList[nextIndex]; // 下一行数据
      console.log("nextIndex:", nextIndex, "before-nextCel:", nextCel);
      if (nextCel) {
        // if (isNaN(curMax)) {
        //   this.del(nextCel);
        //   return false;
        // }
        nextCel.min = curMax + 1; // 保持下一行min数字紧接本行的max
        if (nextCel.min < nextCel.max) {
          console.log("nextIndex:", nextIndex, "min < max:", nextCel);
        } else {
          nextCel.max = nextCel.min + 1; // 保持下一行max数字大于min
          console.log("nextIndex:", nextIndex, "after-nextCel:", nextCel);
        }

        this.$nextTick(() => {
          this.checkMinMaxChange(nextIndex);
        });
      } else {
        console.log("nextIndex:", nextIndex, "no next:", index, nextCel);
      }
    },
    add() {
      if (this.hasEmptyNum) {
        console.log("有修改未保存");
      } else {
        const lastCel = this.avaliableList[this.avaliableList.length - 1];
        console.log("lastCel:", lastCel);
        const min = lastCel ? lastCel.max + 1 : 1;
        const max = min + 1;
        const newCel = {
          avaliable: 1,
          min,
          max,
          freeShipping: 0,
          leap: 1,
        };
        // const unAvaliable = this.findUnAvaliable(newCel);
        // if (unAvaliable) {
        //   unAvaliable.avaliable = 1;
        // } else {
        this.$emit("input", [
          ...this.avaliableList,
          newCel,
          ...this.discardList,
        ]);
        // }
        // setTimeout(() => {
        //   const index = this.value.length - 1;
        //   this.$refs["ladder-input-min-" + index][0].focus();
        // }, 100);
      }
    },
    del(item) {
      if (item.id) {
        item.avaliable = 0;
      } else {
        const deItemIndex = this.value.findIndex((v) => v === item);
        this.value.splice(deItemIndex, 1);
      }
      // const deepCopyVaule = JSON.parse(JSON.stringify(this.avaliableList));
      this.$nextTick(() => {
        // this.$emit("input", this.avaliableList);
        this.checkMinMaxChange();
      });

      // }
    },
  },
};
</script>

<style lang="scss">
@import "@/style/_responsive.scss";
.min-input {
  .el-input__inner {
    text-align: center;
  }
}
.number-input {
  width: 100%;
  @include mobile {
    margin-bottom: 8px;
  }
}
.from-to {
  text-align: center;
}
.el-button.ladder-submit {
  display: block;
  margin-left: 0;
  margin-top: 10px;
}
</style>