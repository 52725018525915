import { render, staticRenderFns } from "./SkuForm.vue?vue&type=template&id=c02ed848"
import script from "./SkuForm.vue?vue&type=script&lang=js"
export * from "./SkuForm.vue?vue&type=script&lang=js"
import style0 from "./SkuForm.vue?vue&type=style&index=0&id=c02ed848&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports