<template>
  <div v-loading="skuLoading">
    <!-- {{ skuData }} -->
    <!-- <p>{{ specData }}</p> -->
    <el-collapse v-model="activeNames" accordion>
      <el-collapse-item
        name="ladderprice"
        :disabled="activeNames === 'ladderprice'"
      >
        <template slot="title">
          {{ $t("product.ladderprice") }}
          <el-button v-if="skuData.length" type="text" icon="el-icon-edit">
            {{ $t("common.modify") }}
          </el-button>
        </template>
        <!-- <sui-form
          ref="ladderForm"
          :list="formList"
          :defaultdata="formData"
          @submit="submit"
          submitTitle="product.generateSkuList"
        /> -->
        <!-- <p>{{ ladderData }}</p> -->
        <!-- minimun input -->
        <el-row>
          <el-col :span="12">
            {{ $t("product.minimun") }}
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-input
              type="number"
              v-model.number="minimun"
              :min="1"
              :step="1"
              step-strictly
              controls-position="right"
              :placeholder="$t('product.minimun')"
              class="number-input"
            >
            </el-input>
          </el-col>
        </el-row>
        <br />
        <ladder-form
          v-model="ladderData"
          @submit="ladderSubmit"
          :minimun="minimun"
        />
      </el-collapse-item>
      <el-collapse-item
        name="skulist"
        :disabled="activeNames === 'ladderprice'"
      >
        <template slot="title">
          {{ $t("product.skulist") }}
        </template>
        <!-- currency select -->
        <el-row>
          <el-col :span="12">
            {{ $t("product.currency") }}
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <exchange-rate-select
              :value="currency"
              @input="inputCurrency"
            ></exchange-rate-select>
          </el-col>
        </el-row>
        <br />
        <bulk-fill-form
          v-if="ladderData.length && activeNames === 'skulist'"
          :specData="specData"
          :ladderData="ladderData"
          @submit="bulkFillSubmit"
        />
        <!-- <p>{{ specData }}</p> -->
        <!-- <p>{{ ladderData }}</p> -->
        <el-form ref="skulistForm">
          <el-row :gutter="10">
            <el-col :span="8" :md="5">
              {{ $t("product.colorText") }}
            </el-col>
            <el-col :span="8" :md="5">
              {{ $t("product.sizeText") }}
            </el-col>
            <el-col :span="8" :md="5">
              {{ $t("product.quantity") }}
            </el-col>
            <el-col :span="12" :md="6">
              {{ $t("product.stock") }}
            </el-col>
            <el-col :span="12" :md="3">
              {{ $t("product.price") }}
            </el-col>
          </el-row>

          <template v-for="(sku, index) in skuData">
            <!-- {{ sku.id }}
            {{ sku.specIdList }}
            {{ sku.skuLadder }} -->
            <el-row :key="`sku-cel-${index}`" :gutter="10">
              <el-col :span="8" :md="5">
                {{ sku.colorText }}
              </el-col>
              <el-col :span="8" :md="5">
                {{ sku.sizeText }}
              </el-col>
              <el-col :span="8" :md="5">
                {{ sku.ladderText }}
              </el-col>
              <el-col :span="12" :md="6">
                <el-form-item>
                  <el-checkbox
                    :true-label="1"
                    :false-label="0"
                    class="right-space"
                    v-model="sku.infStock"
                    >{{ $t("product.infinity") }}</el-checkbox
                  >
                  <el-input-number
                    v-if="!sku.infStock"
                    :min="1"
                    :step="1"
                    :precision="0"
                    v-model.number="sku.stock"
                    size="small"
                    controls-position="right"
                  ></el-input-number>
                </el-form-item>
              </el-col>
              <el-col :span="12" :md="3">
                <el-form-item>
                  <!-- 两位数 -->
                  <price-input
                    v-model.number="sku.price"
                    :config="{
                      size: 'small',
                      controlsPosition: 'right',
                    }"
                  />
                  <!-- 四位数 -->
                  <!-- <input-number-price-four
                    v-model.number="sku.price"
                    :config="{
                      size: 'small',
                      controlsPosition: 'right',
                    }"
                  /> -->
                  <!-- <el-input-number
                    :min="0.01"
                    :step="0.01"
                    :precision="2"
                    
                    size="small"
                    controls-position="right"
                  ></el-input-number> -->
                </el-form-item>
              </el-col>
            </el-row>
          </template>
          <el-form-item>
            <el-button type="primary" class="sku-add" @click="skuSubmit">
              {{ $t("common.submitAndNext") }}
            </el-button>
          </el-form-item>
        </el-form>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import { EditSku, GetSkuList, GetLadderList, EditLadder } from "@/utils/api";
import PriceInput from "@/components/s-ui/form/PriceInput";
import BulkFillForm from "./BulkFillForm";
import LadderForm from "./LadderForm";
import ExchangeRateSelect from "@/components/s-ui/form/ExchangeRateSelect";
// import InputNumberPriceFour from "@/components/s-ui/form/InputNumberPriceFour";
export default {
  components: {
    PriceInput,
    LadderForm,
    BulkFillForm,
    ExchangeRateSelect,
    // InputNumberPriceFour,
  },
  props: {
    spuId: {
      type: String,
    },
    specData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      skuLoading: false,
      activeNames: "ladderprice",
      minimun: 1,
      currency: "",
      // formList: [
      //   {
      //     type: "ladderprice",
      //     name: "ladderprice",
      //     label: "product.ladderprice",
      //     rules: [
      //       {
      //         required: true,
      //       },
      //     ],
      //   },
      // ],
      ladderData: [],
      // formData: {
      //   ladderprice: [],
      // },
      skuData: [],
      usedSkuData: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    inputCurrency(val) {
      // console.log("inputCurrency", val);
      this.currency = val;
    },
    getList() {
      this.skuLoading = true;
      GetLadderList({ spuId: this.spuId })
        .then((res) => {
          console.log("GetLadderList-res:", res);
          this.ladderData = res.data.rows;
          // this.$refs["ladderForm"].setForm({
          //   ladderprice: this.ladderData,
          // });
          this.$nextTick(() => {
            GetSkuList({ spuId: this.spuId })
              .then((res) => {
                console.log("GetSkuList-res:", res);
                const list = res.data.rows;
                this.currency = res.data.spu.currency;
                this.minimun = res.data.spu.minimun;
                if (list.length) {
                  // const ladderprice = this.makeLadder(list);
                  this.usedSkuData = list;
                  // this.$nextTick(() => {
                  //   this.$refs["ladderForm"].setForm({
                  //     ladderprice,
                  //   });
                  //   this.ladderData = ladderprice;
                  this.activeNames = "skulist";
                  this.makeSkuData();
                  // });
                  this.error = null;
                }
                this.skuLoading = false;
              })
              .catch((err) => {
                console.log("GetSkuList-err:", err);
                this.error = err;
                this.skuLoading = false;
              });
          });
        })
        .catch((err) => {
          console.log("GetSkuList-err:", err);
          this.skuLoading = false;
        });
    },

    makeLadder(list) {
      return JSON.parse(
        JSON.stringify(
          list
            .filter((v) => v.avaliable === 1)
            .map((v) => {
              // console.log(v.min, v.max);
              return {
                avaliable: v.avaliable,
                min: v.min,
                max: v.max,
                leap: v.leap,
                freeShipping: v.freeShipping,
              };
            })
            .reduce((all, item) => {
              const isExist = all.some((a) => {
                // console.log("some.min:", a.min, item.min, a.min === item.min);
                // console.log("some.max:", a.max, item.max, a.max === item.max);
                return (
                  a.min === item.min &&
                  a.max === item.max &&
                  a.avaliable === item.avaliable
                );
              });
              // console.log("all, item, isExist:", all, item, isExist);
              if (!all.length || !isExist) {
                return [...all, item];
              } else {
                return all;
              }
            }, [])
        )
      );
    },

    findSkuItem(item) {
      const newSku = this.skuData.find(
        (v) =>
          v.skuLadder.id === item.skuLadder.id &&
          JSON.stringify(v.specIdList) === JSON.stringify(item.specIdList)
      );

      const oldSku = this.usedSkuData.find(
        (v) =>
          v.skuLadder.id === item.skuLadder.id &&
          JSON.stringify(v.specIdList) === JSON.stringify(item.specIdList)
      );
    //   console.log(
    //     "item:",
    //     item,
    //     "newSku:",
    //     newSku,
    //     "oldSku:",
    //     oldSku,
    //     "this.skuData:",
    //     this.skuData
    //   );

      return newSku || oldSku;

      // const targetList = this.usedSkuData;
      // const targetList = this.skuData.length ? this.skuData : this.usedSkuData;
      // if (!targetList.length) {
      //   return {};
      // }
      // const sku = targetList.filter((v) => {
      //   if (
      //     v.skuLadderId === item.skuLadder.id &&
      //     JSON.stringify(v.specIdList) === JSON.stringify(item.specIdList)
      //   ) {
      //     return true;
      //   } else {
      //     return false;
      //   }
      // });
      // console.log('findSkuItem-sku:', sku)
      // return sku.length ? sku[0] : {};
    },

    ladderSubmit(data) {
      console.log("ladderSubmit:", data);
      this.skuLoading = true;
      EditLadder({
        spuId: this.spuId,
        skuLadderlist: data,
      })
        .then((res) => {
          console.log("EditLadder-res:", res);
          this.ladderData = res.data.rows;
          this.$message({
            type: "success",
            message: this.$t("common.optSuccess"),
          });
          this.activeNames = "skulist";
          this.skuLoading = false;
          this.$nextTick(() => {
            this.makeSkuData();
            this.$emit("setLadder", res.data.rows);
          });
        })
        .catch((err) => {
          console.log("EditLadder-err:", err);
          this.skuLoading = false;
        });
      // this.ladderData = ladderprice;
      // this.activeNames = "skulist";
      // this.makeSkuData();
    },

    makeSkuData() {
      const { color, size } = this.specData;
      console.log("makeSkuData-ladder:", this.ladderData);
      console.log(
        "makeSkuData-color:",
        color,
        color.filter((v) => v.avaliable === 1)
      );
      console.log("makeSkuData-size:", size);

      if (this.ladderData && this.ladderData.length) {
        const skuData = color
          .filter((v) => v.avaliable === 1)
          .map((color) => {
            return size
              .filter((v) => v.avaliable === 1)
              .map((size) => {
                return this.ladderData.map((ladder, index) => {
                  // const ladderHasAvaliable =
                  //   Object.prototype.hasOwnProperty.call(ladder, "avaliable");
                  // const avaliable = ladderHasAvaliable
                  //   ? ladder.avaliable
                  //   : color.avaliable && size.avaliable
                  //   ? 1
                  //   : 0;
                  const maxText =
                    index === this.ladderData.length - 1
                      ? "Unlimited"
                      : ladder.max;
                  const ladderText = `${ladder.min}-${maxText}`;
                  const sku = {
                    // spuId: this.spuId,
                    infStock: 0,
                    stock: 0,
                    price: 1,
                    specIdList: [color.id, size.id],
                    ladderText,
                    skuLadder: { id: ladder.id },
                    colorText: color.specName,
                    sizeText: size.specName,
                  };
                  // console.log("makeSkuData-sku:", sku);
                  return {
                    ...sku,
                    ...this.findSkuItem(sku),
                    ladderText,
                    // avaliable: 1,
                  };
                });
              })
              .reduce((a, v) => a.concat(v), []);
          })
          .reduce((a, v) => a.concat(v), []);
        console.log("skuData:", skuData);
        this.skuData = skuData;
      }
    },

    skuSubmit() {
      console.log(this.skuData);
      if (
        this.skuData.some(
          (v) =>
            (v.infStock === 0 && !v.stock) || //  非无限无库存,报错
            !v.price
        )
      ) {
        this.$message({
          type: "error",
          message: this.$t("product.skuerr"),
        });
        return false;
      }
      this.skuLoading = true;
      EditSku({
        minimun: this.minimun,
        currency: this.currency,
        spuId: this.spuId,
        skuList: this.skuData,
      })
        .then((res) => {
          console.log("EditSku-res:", res);
          this.$message({
            type: "success",
            message: this.$t("common.optSuccess"),
          });
          this.skuLoading = false;
          this.getList();
          this.$emit("success", { ...res.data });
        })
        .catch((err) => {
          this.skuLoading = false;
          console.log("EditSku-err:", err);
        });
    },

    bulkFillSubmit(data) {
      console.log("bulkFillSubmit-data:", data);
      const { colorId, sizeId, ladderId, infStock, stock, price } = data;
      console.log(colorId, sizeId, ladderId, infStock, stock, price);
      this.skuData.forEach((sku) => {
        // console.log("sku1:", sku);
        const {
          specIdList: [skuColorId, skuSizeId],
          skuLadder: { id: skuLadderId },
        } = sku;
        if (
          (skuColorId === colorId || !colorId) &&
          (sizeId === skuSizeId || !sizeId) &&
          (ladderId === skuLadderId || !ladderId)
        ) {
          this.$set(sku, "price", price);
          this.$set(sku, "stock", stock);
          this.$set(sku, "infStock", infStock);
          // sku.price = price;
          // sku.stock = stock;
          // sku.infStock = infStock;
        } else {
          console.log(
            skuColorId === colorId || !colorId,
            sizeId === skuSizeId || !skuSizeId,
            ladderId === skuLadderId || !ladderId
          );
        }
        // console.log("sku2:", sku);
      });
    },
  },
};
</script>

<style>
.sku-add {
  display: block;
  width: 100%;
}
.el-checkbox.right-space {
  margin-right: 8px;
}
</style>